@import "~@mdi/font/css/materialdesignicons.css";
@import "./theme/assets/scss/style";

.border-r-30 {
  border-radius: 30px;
}

a.offset-anchor {
  display: block;
  position: relative;
  top: -70px;
  visibility: hidden;
}

.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  border-radius: 0;
  background-color: #e3f5ff;
}

.bg-gradient-light-blue{
  background: linear-gradient(to right, #093485, #fff,#fff, #093485);
}

.language-selection-container{
  position:absolute;
  top:50%;
  left:0;
  right: 0;
  z-index: 99999;
  transform: translate(0, -50%);
}
//.language-selection-1 {
//  position:absolute;
//  top: 50%;
//  left: 25%;
//  transform: translate(-50%, -50%);
//}
//.language-selection-2 {
//  position:absolute;
//  top: 50%;
//  left: 75%;
//  transform: translate(-50%, -50%);
//}
.btn-language-selection {
    background-color: #48abe0;
    color: white;
    border: none;
    padding: 5px;
    font-size: 31px;
    height: 130px;
    width: 130px;
    box-shadow: 0 2px 4px darkslategray;
    cursor: pointer;
    transition: all 0.2s ease;
}