/*-------------------------------------------------------------------
  ===== Table of Contents =====

  * Bootstrap functions
  * Template variables
  * SCSS Compass Functions
  * Boostrap Main SCSS
  * Template mixins
    + Animation Mixins
    + Background Mixins
    + Badge Mixins
    + Button Mixins
    + Miscellaneous Mixins
    + BlockQuote Mixins
    + Cards Mixins
    + Color Functions Mixins
    + Tooltips
    + popovers
  * Core Styles
    + Reset Styles
    + Fonts
    + Functions
    + Backgrounds
    + Typography
    + Miscellaneous
    + Footer
    + Layouts
    + Utilities
    + Demo styles
  * Components
    + Badges
    + Boostrap Progress
    + Buttons
    + Breadcrumbs
    + Cards
    + Checkboxes and Radios
    + Dropdowns
    + Forms
    + Icons
    + Preview
    + Tables
    + Todo List
  * Landing screens
    + Auth
-------------------------------------------------------------------*/

/*-------------------------------------------------------------------*/
/* === Import Bootstrap functions and variables === */
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

/*-------------------------------------------------------------------*/
/* === Import template variables === */
@import './variables';

/*-------------------------------------------------------------------*/
/* === SCSS Compass Functions === */
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";

/*-------------------------------------------------------------------*/
/* === Boostrap Main SCSS === */
@import "~bootstrap/scss/bootstrap";

/*-------------------------------------------------------------------*/
/* === Template mixins === */
@import "./mixins/misc.scss";
@import "./mixins/animation.scss";
@import "./mixins/background.scss";
@import "./mixins/badges.scss";
@import "./mixins/blockqoute.scss";
@import "./mixins/buttons.scss";
@import "./mixins/breadcrumbs.scss";
@import "./mixins/cards.scss";
@import "./mixins/pagination.scss";
@import "./mixins/popovers.scss";
@import "./mixins/tooltips.scss";
@import "./mixins/text.scss";

/*-------------------------------------------------------------------*/
/* === Core Styles === */
@import "./_reset.scss";
@import "./_fonts.scss";
@import "./_functions.scss";
@import "./_typography.scss";
@import "./_misc.scss";
@import "./_footer.scss";
@import "./_utilities.scss";
@import "./_demo.scss";
@import "./_dashboard.scss";

/*-------------------------------------------------------------------*/
/* === Components === */
@import "./components/badges.scss";
@import "./components/bootstrap-progress.scss";
@import "./components/buttons.scss";
@import "./components/breadcrumbs.scss";
@import "./components/cards.scss";
@import "./components/checkbox-radio.scss";
@import "./components/dropdown.scss";
@import "./components/forms.scss";
@import "./components/icons.scss";
@import "./components/lists.scss";
@import "./components/preview.scss";
@import "./components/tables.scss";
@import "./components/todo-list.scss";

/*-------------------------------------------------------------------*/
/* === Landing screens === */
@import "./landing-screens/auth.scss";


@import './_navbar.scss';
@import './_sidebar.scss';
@import './_layouts.scss';
@import './_vertical-wrapper.scss';

.flipping-bubble-container{
  position: relative;
  z-index: 1;
  perspective: 1000px;

}

.flipping-bubble-card{
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 0.8s ease;

}

.flipping-bubble-container:hover .flipping-bubble-card{
  transform: rotateY(180deg);
}

.flipping-bubble-front{
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flipping-bubble-back{
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform : rotateY(180deg);
}

.flip-bubble-danger{

  .flipping-bubble-front{
    @extend .border;
    @extend .border-3;
    @extend .border-danger;
    @extend .bg-white;
  }

  .flipping-bubble-back{
    @extend .bg-danger;
  }

}

.flip-bubble-secondary{

  .flipping-bubble-front{
    @extend .border;
    @extend .border-3;
    @extend .border-secondary;
    @extend .bg-white;
  }

  .flipping-bubble-back{
    @extend .bg-secondary;
  }

}

.flip-bubble-primary{

  .flipping-bubble-front{
    @extend .border;
    @extend .border-3;
    @extend .border-primary;
    @extend .bg-white;
  }

  .flipping-bubble-back{
    @extend .bg-primary;
    @extend .text-white;
  }

}

.flip-bubble-info{

  .flipping-bubble-front{
    @extend .border;
    @extend .border-3;
    @extend .border-info;
    @extend .bg-white;
  }

  .flipping-bubble-back{
    @extend .bg-info;
    @extend .text-white;
  }

}

.flip-bubble-success{

  .flipping-bubble-front{
    @extend .border;
    @extend .border-3;
    @extend .border-success;
    @extend .bg-white;
  }

  .flipping-bubble-back{
    @extend .bg-success;
    @extend .text-white;
  }

}

